import { GetStaticProps, NextPage } from 'next'

import Home, { IHome } from '@/components/screens/home/Home'

import { CatalogService } from '@/services'

const HomePage: NextPage = ({ best, like }: IHome) => {
	return <Home best={best} like={like} />
}

export const getStaticProps: GetStaticProps = async () => {
	try {
		const best = await CatalogService.getBest()
		const like = await CatalogService.getLike()
		return {
			props: { best, like },
			revalidate: 60,
		}
	} catch (error) {
		console.log(error, 'Home page')

		return {
			notFound: true,
			revalidate: 30,
		}
	}
}

export default HomePage
