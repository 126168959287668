export const getKeys = <T>(obj: any) => Object.keys(obj) as Array<keyof T>

export const checkNull = (obj: any) => {
	if (typeof obj === 'object' && obj !== null) {
		return obj
	}
	if (typeof obj === 'string') {
		return obj
	}
	if (typeof obj === 'number') {
		return obj
	}
	if (typeof obj === 'undefined') {
		return '-'
	} else return '-'
}
