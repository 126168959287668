export const formatRusTime = (d: Date) => {
	const date = d.getDate().toString().padStart(2, '0')
	const month = d.getMonth()
	const monthTitle = [
		'января',
		'февраля',
		'марта',
		'апреля',
		'мая',
		'июня',
		'июля',
		'августа',
		'сентября',
		'октября',
		'ноября',
		'декабря',
	]
	const year = d.getFullYear()
	const h = d.getHours().toString().padStart(2, '0')
	const m = d.getMinutes().toString().padStart(2, '0')
	return `${date} ${monthTitle[month]} ${year}, ${h}:${m}`
}
