import 'keen-slider/keen-slider.min.css'
import { FC } from 'react'

import { Slider, SubHeading } from '@/ui'

import { IGood } from '@/shared'

import { Meta } from '@/utils'

import styles from './Home.module.scss'

export interface IHome {
	best: IGood[]
	like: IGood[]
}

const Home: FC<IHome> = ({ best = [], like = [] }: IHome) => {
	return (
		<Meta title="" description="вседлякомпьютеров">
			<div className={styles.home}>
				<div className={styles.block}>
					{best.length ? (
						<div className={styles.best}>
							<SubHeading title="Лучшие товары недели" />
							<Slider body={best} styles={styles} />
						</div>
					) : null}
					{like.length ? (
						<div className={styles.like}>
							<SubHeading title="Возможно вам понравится" />
							<Slider body={like} styles={styles} />
						</div>
					) : null}
				</div>
			</div>
		</Meta>
	)
}

export default Home
